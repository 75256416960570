import React from "react";

export const experienceConstant = [
    {
        "name": "The Brite Group",
        "startDate": "Jun 2022",
        "endDate": "Aug 2022",
        "position": "Data Science Intern",
        "imgPath": "img/logos/workplaces/thebritegroup.jpg",
        "oneLiner": "Natural Language Processing (NLP) research and training using transformers for use in US Federal Dept. Contracts",
        "longDesc": "",
    }
]